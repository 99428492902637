import { isResponseError, ResponseError } from '../error/ResponseError'
import { LoggingProvider } from './LoggingProvider'

export interface ErrorInfo {
    componentStack: string
}

class Logger {
    private loggingProvider: LoggingProvider | null = null

    public init = (loggingProvider: LoggingProvider | null) => {
        this.loggingProvider = loggingProvider
    }

    public handleError = (e: Error, message?: string) => {
        const options = message ? { message } : undefined
        if (this.isOfflineError(e)) {
            return
        }
        if (isResponseError(e)) {
            this.handleResponseErrorInternal(e)
        } else {
            this.handleErrorInternal(e, options)
        }
    }

    public handleErrorWithErrorInfo = (e: Error, errorInfo?: ErrorInfo) => {
        const options = errorInfo ? { errorInfo } : undefined
        this.handleErrorInternal(e, options)
    }

    private handleErrorInternal = (e: Error, options?: object) => {
        // eslint-disable-next-line no-console
        console.error(e, options)
        if (this.loggingProvider) {
            this.loggingProvider.captureException(e, options)
        }
    }

    private handleResponseErrorInternal = (e: ResponseError) => {
        if (e.originalResponse.status && e.originalResponse.status !== 401) {
            if (e.errorPayload) {
                this.handleErrorInternal(e, {
                    status: e.originalResponse.status,
                    url: e.originalResponse.url,
                    payload: e.errorPayload,
                })
            } else {
                e.originalResponse.text().then(text =>
                    this.handleErrorInternal(e, {
                        status: e.originalResponse.status,
                        url: e.originalResponse.url,
                        payload: text,
                    }),
                )
            }
        }
    }

    private isOfflineError = (e: Error) =>
        e.name === 'TypeError' && e.message && e.message.includes('fetch')
}

export const logger = new Logger()
