import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import { currentTimeUTCSelector } from 'aos-services/src/core/common/selectors'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import {
    AirportFilter,
    AirportFilterSingle,
    FilterOption,
} from 'aos-services/src/services/flightInformation/types/FlightInfoFilters'
import { getAirportName } from 'aos-services/src/services/flightInformation/types/FlightInfoFormatter'
import {
    endsIn8Hours,
    isSnowtam,
    Notam,
    NotamTimelineItem,
    Snowtam,
} from 'aos-services/src/services/flightInformation/types/Notam'
import { Color } from 'aos-ui-common/src/styles/Color'
import { EnumValues } from 'enum-values'
import { chain } from 'lodash'
import moment, { Moment } from 'moment'
import { createSelector } from 'reselect'

import { flightsUrlFiltersSelector } from '../flightInformation/selectors'
import { NotamStateAware } from './state'

export const selectNotamList = (state: NotamStateAware) => [
    ...state.notam.list,
    ...state.notam.snowtam,
]
export const collapsedGroupsSelector = (state: NotamStateAware) => state.notam.collapsedGroups
export const notamItemSelector = (state: NotamStateAware) => state.notam.selectedItem
export const lastUpdatedSelector = (state: NotamStateAware) => state.notam.lastUpdated
export const groupsSelector = createSelector(
    selectNotamList,
    collapsedGroupsSelector,
    flightsUrlFiltersSelector,
    currentUserSiteLocation,
    (list, collapsedGroups, filters, site) => {
        const totalGroups = filters.airport.includes(FilterOption.All)
            ? [FilterOption.All, ...EnumValues.getValues(AosAirport)].length
            : filters.airport.length
        const minHeight = Math.max((window.innerHeight - 250) / totalGroups, 200)
        const groups = chain(list)
            .filter(filterItem(filters.airport, site))
            .groupBy('siteLocation')
            .mapValues((_, group) => {
                const isCollapsed = collapsedGroups.includes(group)
                return {
                    id: group,
                    isCollapsed: isCollapsed,
                    timelineLabel: getAirportName(group as AosAirport),
                    lineHeight: isCollapsed ? 10 : 55,
                    minHeight,
                    rootKey: group,
                }
            })
            .values()
            .sortBy(item => (item.id === site ? 0 : 1))
            .value()
        filters.airport.forEach((airport: AosAirport | FilterOption) => {
            if (
                airport !== FilterOption.All &&
                groups.find(group => group.id === airport) === undefined
            ) {
                groups.push({
                    id: airport,
                    isCollapsed: false,
                    timelineLabel: getAirportName(airport as AosAirport),
                    lineHeight: 55,
                    minHeight,
                    rootKey: airport,
                })
            }
        })
        return groups
    },
)

export const notamBorderColor = (notam: Notam | Snowtam) => {
    if (isSnowtam(notam)) {
        return Color.Grey
    }
    if (endsIn8Hours(notam)) {
        return Color.Orange
    }
    return Color.Grey
}

function convertToUTC(startTime: Moment, endTime: Moment) {
    const localTimeZone = moment.tz.guess()

    const startTimeLocal = moment.tz(startTime, localTimeZone)
    const endTimeLocal = moment.tz(endTime, localTimeZone)

    const startTimeUTC = startTimeLocal.clone().subtract(startTimeLocal.utcOffset(), 'minute')
    const endTimeUTC = endTimeLocal.clone().subtract(endTimeLocal.utcOffset(), 'minute')

    return {
        startTime: startTimeUTC,
        endTime: endTimeUTC,
    }
}

const itemsSelector = createSelector(
    selectNotamList,
    (state: NotamStateAware) => state.notam.collapsedGroups,
    notamItemSelector,
    flightsUrlFiltersSelector,
    currentUserSiteLocation,
    (notam, collapsedGroups, selectedItem, filters, site): NotamTimelineItem[] => {
        return notam
            .filter(filterItem(filters.airport, site))
            .filter(item => (filters.airport.length === 1 ? !isSnowtam(item) : true))
            .map(item => {
                return {
                    id: item.number,
                    group: item.siteLocation,
                    outlined: selectedItem?.number !== item.number,
                    color: Color.Grey,
                    borderColor: notamBorderColor(item),
                    ...convertToUTC(item.startTime, item.endTime),
                    isCollapsed: collapsedGroups.includes(item.siteLocation),
                    payload: item,
                }
            })
    },
)

const filterItem = (airports: AirportFilter, site: AirportFilterSingle) => (item: Notam) => {
    return airports
        ? (airports as (AosAirport | FilterOption)[]).includes(FilterOption.All)
            ? true
            : airports.includes(item.siteLocation as never)
        : item.siteLocation === site
}

export const notamTimelineSelector = createSelector(
    (state: NotamStateAware) => state.notam.timeline,
    currentTimeUTCSelector,
    itemsSelector,
    groupsSelector,
    (timelineState, currentTime, items, groups) => ({
        groups,
        items,
        currentTime,
        timelineState,
    }),
)

export const snowtamSelector = (state: NotamStateAware) => state.notam.snowtam

export const snowtamPerAirportSelector = createSelector(
    snowtamSelector,
    flightsUrlFiltersSelector,
    (snowtam, filters) =>
        snowtam.find(
            s => s.siteLocation === filters.airport[0] && moment(s.endTime).isAfter(moment()),
        ),
)

export const notamTileSelector = createSelector(
    (state: NotamStateAware) => state.notam.list,
    snowtamSelector,
    currentUserSiteLocation,
    (list: Notam[], snowtam, location) => ({
        notam: list.filter(
            item =>
                item.siteLocation === location &&
                dateBetween(moment(), moment(item.startTime), moment(item.endTime)),
        ).length,
        snowtam: snowtam.filter(
            item =>
                item.siteLocation === location &&
                dateBetween(moment(), moment(item.startTime), moment(item.endTime)),
        ).length,
    }),
)

const dateBetween = (date: moment.Moment, start: moment.Moment, end: moment.Moment) => {
    return date.isBetween(start, end, 'second', '[]')
}
